import React, {
  useEffect,
  //useState
} from "react"
import { Layout as AntLayout } from "antd"
import { css } from "twin.macro"
import { ToastProvider } from "react-toast-notifications"
import {
  //QueryResult,
  useQuery
} from "@apollo/client"

import "./global.css"
import { Localization, possibleOptions } from "@src/localization"
import Header from "./header"
import Footer from "./footer"
import CurrentStep from "@src/context/currentRouteContext"
import useCurrentRoute from "@src/hooks/useCurrentRoute"
import { GET_USER, User } from "@src/queries/user"
import Loading from "@src/components/loading"
import ErrorComponent from "@src/components/ui/error"
import { canAccessEBlast } from "@src/utils/accessControl"
import {
  //setJwtToken,
  //useFetchMsalTokenAndUserCustom
} from "@src/utils/auth";

import { 
  getB2cConfig, 
  //b2cPolicies 
} from "@src/msalConfig";
//import { msalConfig } from "@src/msalConfig";
import {
  InteractionType

 } from "@azure/msal-browser";
import {
  //  useMsal,
  //  useAccount,
   useIsAuthenticated,
   useMsalAuthentication,
   UnauthenticatedTemplate,
   AuthenticatedTemplate,
} from "@azure/msal-react";


const Layout = ({
  children,
  isPublic = false,
}: {
  children: any
  isPublic?: boolean
}): React.ReactElement => {
  //const [user, setUser] = React.useState<User | null>(null)
  //const [getUserResp, setGetUserResp] = useState<QueryResult | null>(null);
  const [isAuthenticatedState, setIsAuthenticatedState] = React.useState<boolean>(false)
  const isBrowser = typeof window !== "undefined";
  const getWindowLocation = () => {if(isBrowser){return window.location.search} else {return '';}};
  const queryParams = new URLSearchParams(getWindowLocation());
  const authExperience = queryParams.get("authExperience")??"default";
  const loginRequest = getB2cConfig("", authExperience);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  //const msalAuth =
     useMsalAuthentication(InteractionType.Redirect,loginRequest);
  // const { /*instance,*/ accounts } = useMsal();
  // const account = useAccount(accounts[0]);
  
  const isAuthenticated = useIsAuthenticated();
  useEffect(() => {
    setIsAuthenticatedState(isAuthenticated);   
  }, [isAuthenticated])

  const currentStep = useCurrentRoute();
  //const isBrowser = typeof window !== "undefined";


  let user:(User|null) = null;
  let showLoading = true;
  const getUserResp = useQuery(GET_USER,{ skip:isAuthenticatedState===false });
  user =getUserResp && getUserResp.data && getUserResp.data.user;
  showLoading = (!isAuthenticatedState || getUserResp==null || getUserResp.loading);

  const showError:boolean = (!showLoading && !isPublic && (!user || !canAccessEBlast(user)));
  const showMain = !(showLoading||showError);


  return (
    <>
      <AuthenticatedTemplate>
{ showMain &&
          <Localization.Provider value={possibleOptions.en}>
            <CurrentStep.Provider value={currentStep}>
              <ToastProvider>
                <AntLayout hasSider={false}>
                  <Header isPublic={isPublic} />
                  <AntLayout.Content
                    css={[
                      css`
                        padding-top: 64px;
                      `,
                    ]}
                  >
                    {children}
                  </AntLayout.Content>
                  <Footer />
                </AntLayout>
              </ToastProvider>
            </CurrentStep.Provider>
          </Localization.Provider>
}
{ showLoading &&
          <Loading withOverlay />
}
{ showError &&
          <ErrorComponent msg="Looks like you don't have access to eBlast application. Please contact 4PatientCare Support for access." />
}
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          {isPublic &&
            <Localization.Provider value={possibleOptions.en}>
              <CurrentStep.Provider value={currentStep}>
                <ToastProvider>
                  <AntLayout hasSider={false}>
                    <Header isPublic={isPublic} />
                    <AntLayout.Content
                      css={[
                        css`
                          padding-top: 64px;
                        `,
                      ]}
                    >
                      {children}
                    </AntLayout.Content>
                    <Footer />
                  </AntLayout>
                </ToastProvider>
              </CurrentStep.Provider>
            </Localization.Provider>
          }
          {/* <ErrorComponent msg="Looks like you don't have access to eBlast application. Please contact 4PatientCare Support for access." />       */}
          {!isPublic &&
            <div>Signing In</div>
          }
        </UnauthenticatedTemplate>
    </>
  )
}

export default Layout
